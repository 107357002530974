import React from "react";
import Button from "../../atoms/button/Button";
import Card from "../../atoms/card/Card";
import { GlossaryContentStyles } from "./glossaryContent.styles";
import { CardData, GlossaryContentTypes } from "./glossaryContent.types";

export default function GlossaryContent({
  subHeading,
  btnText,
  btnClick,
  cardsData,
  cardClassName,
}: GlossaryContentTypes) {
  return (
    <GlossaryContentStyles>
      <div className="heading font-secondary fs-14 fw-600">GLOSSARY</div>
      <div className="subHeading-container pt-16">
        <div className="subHeading font-primary fw-500 fs-48">{subHeading}</div>
      </div>
      <div className="card-container pt-48 w-100">
        {cardsData?.map((cardData: CardData, index: number) => (
          <Card
            key={index + cardData.heading}
            Icon={cardData.Icon}
            imageUrl={cardData.imageUrl}
            heading={cardData.heading}
            description={cardData.description}
            className={`col-4 ${cardClassName}`}
          />
        ))}
      </div>
      {btnText && (
        <div className="btn pt-48 pb-40 text-center">
          <Button
            theme="secondary"
            className="font-primary fs-18 fw-450 py-13 px-32"
            onClick={btnClick}
          >
            {btnText}
          </Button>
        </div>
      )}
    </GlossaryContentStyles>
  );
}
