import styled from "styled-components";

export const GlossaryContentStyles = styled.div`
  padding: 40px 120px;

  .heading {
    color: var(--secondary-color);
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .subHeading-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .subHeading {
      max-width: 588px;
      text-align: center;
      line-height: 61px;
      letter-spacing: -0.01em;
    }
  }

  .card-container {
    display: flex;
    gap: 24px;
  }

  .btn {
    display: none;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 24px;

    .subHeading-container {
      .subHeading {
        font-size: 28px !important;
        line-height: 35px;
      }
    }

    .card-container {
      flex-direction: column;
  }
`;
