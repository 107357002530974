import styled from "styled-components";

export const FooterContentStyles = styled.div`
  color: var(--white-color);
  padding: 64px 164px;
  display: flex;

  .copyright-and-social-section {
    color: #d9dbe1;

    .copyright,
    .rights {
      line-height: 24px;
      font-feature-settings: "salt" on, "liga" off;
    }

    .icons-container {
      display: flex;
      gap: 16px;
    }
  }

  .links-container {
    display: flex;

    .links-section {
      width: 100%;
      max-width: 160px;
    }
  }

  @media screen and (max-width: 1440px) {
    padding: 64px 64px;
  }

  @media screen and (max-width: 1024px) {
    padding: 43px 36px;
    flex-direction: column;

    .links-container {
      width: 100% !important;
      justify-content: space-between;
      padding-top: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 43px 36px;
    flex-direction: column;
    
    .copyright-and-social-section {
      width: 100% !important;

      icons-container {
        padding-top: 40px;
      }
    }
    .links-container {
      flex-direction: column;
      padding-top: 0px;
      width: 100% !important;

      .links-section {
        padding-top: 60px;
      }
    }

    .input-container {
      margin-top: 12px !important;
    }
`;
