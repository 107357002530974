import styled from "styled-components";
import { MobileNavTypes } from "./navBar.types";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 120px 20px 110px;

  @media (max-width: 1024px) {
    padding: 20px;
  }

  .logo {
    @media screen and (max-width: 768px) {
      img {
        width: 56px;
        height: 48px;
      }
  }
`;

export const Nav = styled.nav`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileNav = styled.nav<MobileNavTypes>`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }

  background-color: rgba(255, 255, 255, 0);
  transition: 0.3s ease-in-out background-color;

  .create-account-container {
    display: none;
  }

  ${({ active }) =>
    active &&
    `
    position: absolute;
    inset: 0;
    z-index: 99;
    background-color: var(--white-color);
    padding: 20px;

    .create-account-container {
      display: block;
    }
    `}

  // HAMBURGER BUTTON CSS - START
  .hamburger-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: ${({ active }) => (active ? "block" : "none")};
    }
    .hamburger-menu-button {
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      padding: 15px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0);
      cursor: pointer;
      transition: 0.15s ease background-color;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .visuallyHidden {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }

      .hamburger {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        position: relative;
        cursor: pointer;
      }

      .hamburger .bar {
        padding: 0;
        width: 18px;
        height: 2px;
        background-color: var(
          --${({ active }) => (active ? "black" : "white")}-color
        );
        display: block;
        border-radius: 4px;
        transition: all 0.4s ease-in-out;
        position: absolute;
      }

      .bar1 {
        top: 0;
      }

      .bar2,
      .bar3 {
        top: 13.5px;
      }

      .bar3 {
        right: 0;
      }

      .bar4 {
        bottom: 0;
      }

      .hamburger3 .bar1 {
        transform-origin: 5%;
      }

      .hamburger3 .bar4 {
        transform-origin: 5%;
      }

      .checkbox3:checked + label > .hamburger3 > .bar1 {
        transform: rotate(45deg);
        height: 2px;
        width: 42px;
      }

      .checkbox3:checked + label > .hamburger3 > .bar3 {
        transform: rotate(45deg);
        height: 2px;
        background-color: transparent;
      }

      .checkbox3:checked + label > .hamburger3 > .bar2 {
        transform: rotate(-45deg);
        height: 2px;
        background-color: transparent;
      }

      .checkbox3:checked + label > .hamburger3 > .bar4 {
        transform: rotate(-45deg);
        height: 2px;
        width: 42px;
      }
    }
  }
  // HAMBURGER BUTTON CSS - END
`;

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
`;

export const MobileNavList = styled.div<MobileNavTypes>`
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
  }

  display: none;
  opacity: 0;
  color: rgba(255, 255, 255, 0);
  transition: 0.15s ease-in-out all;
  ${({ active }) =>
    active &&
    `
  display: block;
  opacity: 1;
  color: var(--black-color);
  `}
`;

export const MobileNavLink = styled.li`
  outline: none;
  border-bottom: 1px solid #d8d8d8;
  padding: 12px 0;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:active {
    outline: none;
  }

  background-color: #f5f5f500;
  transition: 0.15s ease-in-out background-color;

  &:hover {
    background-color: #f5f5f5;
  }

  span,
  a {
    // color: var(--black-color);
    cursor: pointer;
    outline: none;
  }
`;

export const NavLink = styled.li`
  outline: none;
  &:active {
    outline: none;
  }
  span,
  a {
    color: var(--white-color);
    cursor: pointer;
    margin: 0 16px;
    transition: 0.15s ease filter;
    outline: none;

    &:hover,
    &:active,
    &:focus {
      filter: drop-shadow(0px 0px 5px var(--secondary-color));
    }
  }
`;

export const Seperator = styled.div`
  height: 70%;
  width: 1px;
  border: 1px solid #545cb5;
`;
