import React from "react";
import { AboutContentStyles } from "./aboutContent.styles";

export default function AboutContent() {
  return (
    <AboutContentStyles>
      <div className="about-us">
        <div className="font-primary fs-48 fw-500 heading">
          <span className="name">QNucleon</span> is improving the understanding
          of quantum mechanics
        </div>
        <div className="description font-primary fw-450 fs-16 pt-24">
          <div>
            QNucleon is quickly evolving into our world where quantum
            information sciences will soon be used for wonderful, new
            inventions, and also likely used to attack us as we stand currently
            today.
          </div>
          <div className="pt-16">
            QNucleon plans to be working with computing devices can use quantum
            mechanics Partners like SpinQ are currently in talks with
            QNucleon in research and development into creating a decentralise
            Quantum Blockchain Network.
          </div>
        </div>
      </div>
      <div className="img-container">
        <img src="static/svgs/atom.svg" alt="nucleon" />
      </div>
    </AboutContentStyles>
  );
}
