import React from "react";
import { FeatureContentStyles } from "./featureContent.styles";
import { FeatureContentTypes } from "./featureContent.types";

export default function FeatureContent({
  contentDirection,
  Icon,
  heading,
  description,
  link,
  imageUrl,
}: FeatureContentTypes) {
  return (
    <FeatureContentStyles contentDirection={contentDirection}>
      <div className="feature-content-container col-6">
        <div className="icon">
          {typeof Icon === "string" && <img src={Icon} alt="icon" />}
          {typeof Icon === "function" && <Icon />}
        </div>
        <div className="heading fs-48 fw-500 font-primary pt-27">{heading}</div>
        <div className="description fs-16 fw-450 font-primary pt-24">
          {description}
        </div>
        {link && (
          <div className="link pt-24 font-primary fs-18 fw-450">
            <a href={link} target="_blank" rel="noreferrer">
              <div>
                <div>See services</div>{" "}
                <img src="static/svgs/arrow-up-right.svg" alt="" />
              </div>
            </a>
          </div>
        )}
      </div>
      <div className="feature-image-container col-6">
        <img src={imageUrl} alt={heading} />
      </div>
    </FeatureContentStyles>
  );
}
