import styled from "styled-components";
import { StylePropsType } from "./featureContent.types";

export const FeatureContentStyles = styled.div<StylePropsType>`
  display: flex;
  flex-direction: ${({ contentDirection }) =>
    contentDirection ? contentDirection : "row"};
  align-items: center;
  padding: 80px 120px;

  .feature-content-container {
    ${({ contentDirection }) =>
      contentDirection ? "margin-left: 20px;" : "margin-right: 20px;"}
    .icon {
      width: 55px;
      height: 55px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .heading {
      line-height: 61px;
      letter-spacing: -0.01em;
    }

    .description {
      line-height: 24px;
      color: #5a5959;
    }

    .link {
      a {
        line-height: 24px;
        color: #384099;
        font-feature-settings: "salt" on, "liga" off;
        text-decoration: none;

        div {
          display: inline-flex;
          position: relative;
          gap: 4px;
          align-items: center;
          justify-content: center;

          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: var(--primary-color);
            transition: 0.15s ease all;
          }

          img {
            transition: 0.15s ease transform;
          }
        }

        &:hover {
          div {
            img {
              transform: translate(1px, -1px);
            }
            ::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .feature-image-container {
    text-align: ${({ contentDirection }) => !contentDirection && "right"};

    img {
      max-width: 486px;
      max-height: 331px;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 24px;
    flex-direction: ${({ contentDirection }) =>
      contentDirection === "row" && "column"};
    flex-direction: ${({ contentDirection }) =>
      contentDirection === "column" && "row"};
    flex-direction: ${({ contentDirection }) =>
      contentDirection === "row-reverse" && "column"};
    flex-direction: ${({ contentDirection }) =>
      contentDirection === "column-reverse" && "row"};
      flex-direction: ${({ contentDirection }) =>
        !contentDirection && "column"};
        
  .feature-content-container, .feature-image-container {
    margin-right: 0;
    margin-left: 0;
    width: 100% !important;
  }

  .feature-image-container {
    text-align: center;
  }

  .heading {
    font-size: 24px !important;
    padding-top: 24px !important;
    line-height: 35px !important;
  }

  .description {
    font-size: 14px !important;
    padding-top: 16px !important;
    padding-bottom: 16px;
  }

  .link {
    display: none;
  }
`;
