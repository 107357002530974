import styled from "styled-components";

export const FooterLinkStyles = styled.div`
  width: 100%;
  max-width: 160px;
  line-height: 26px;
  font-feature-settings: "salt" on, "liga" off;

  a {
    color: #d9dbe1;
    text-decoration: none;
    transition: 0.15s ease color;
  }

  a:hover {
    color: #fff;
  }
`;

export const FooterItemStyles = styled.div`
  width: 100%;
  max-width: 160px;
  line-height: 26px;
  font-feature-settings: "salt" on, "liga" off;
  color: #d9dbe1;
`;
