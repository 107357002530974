import React from "react";
import { useState } from "react";
import FooterLink from "../../atoms/footerLink/FooterLink";
import InputField from "../../atoms/inputField/InputField";
import SocialIcon from "../../atoms/socialIcon/SocialIcon";
import { FooterContentStyles } from "./footerContent.styles";

export default function FooterContent() {
  const [email, setEmail] = useState("");

  return (
    <FooterContentStyles>
      <div className="copyright-and-social-section col-5">
        <div>
          <img
            src="static/images/white-logo.png"
            alt="logo"
            width={90}
            height={81}
          />
        </div>
        <div className="font-primary fs-14 fw-450 pt-20">
          <div className="copyright">
            Copyright &copy; 2022 QNucleon Pty. Ltd.
          </div>
          <div className="rights pt-8">All rights reserved</div>
        </div>
        <div className="icons-container pt-40 ">
          <SocialIcon
            Icon="static/svgs/instagram.svg"
            link="https://instagram.com/"
          />
          <SocialIcon
            Icon="static/svgs/globe.svg"
            link="https://blockchain.com/"
          />
          <SocialIcon
            Icon="static/svgs/twitter.svg"
            link="https://twitter.com/"
          />
          <SocialIcon
            Icon="static/svgs/youtube.svg"
            link="https://youtube.com/"
          />
        </div>
      </div>

      <div className="links-container col-7">
        <div className="links-section col-3">
          <FooterLink
            className="font-secondary fw-450 fs-16 pb-24"
            style={{ color: "var(--white-color)" }}
          >
            Company
          </FooterLink>

          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            About us
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            Blog
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            Contact us
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            Pricing
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16"
          >
            Testimonials
          </FooterLink>
        </div>

        <div className="links-section col-3">
          <FooterLink
            className="font-secondary fw-450 fs-16 pb-24"
            style={{ color: "var(--white-color)" }}
          >
            Support
          </FooterLink>

          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            Help center
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            Terms of service
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            Legal
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16 mb-12"
          >
            Privacy policy
          </FooterLink>
          <FooterLink
            href="https://qnucleon.io/"
            className="font-secondary fw-450 fs-16"
          >
            Status
          </FooterLink>
        </div>

        <div className="pt-60">
          <FooterLink
            className="font-primary fw-500 fs-20"
            style={{ color: "var(--white-color)" }}
          >
            Stay up to date
          </FooterLink>
          <div className="input-container mt-33">
            <InputField
              className="fs-14 fw-450 font-primary py-8 px-12"
              placeholder="Your email address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              icon="static/svgs/send.svg"
              handleSubmit={(e) => null}
              onSubmit={(e) => {
                e.preventDefault();
                return null;
              }}
            />
          </div>
        </div>
      </div>
    </FooterContentStyles>
  );
}
