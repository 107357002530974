import React from "react";
import { StyledBtn } from "./button.styles";
import { ButtonType } from "./button.types";

export default function Button(
  { children, theme, className, onClick }: ButtonType,
  props: object
) {
  return (
    <StyledBtn
      onClick={onClick}
      tabIndex={0}
      theme={theme}
      className={className}
      {...props}
    >
      {children}
    </StyledBtn>
  );
}
