import styled from "styled-components";

export const TopSection = styled.div`
  min-height: 661px;
  background-color: var(--primary-color);
  background-image: url("static/svgs/top-section-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;

  @media screen and (max-width: 768px) {
    min-height: 373px;
    background-size: cover;
    background-position: left bottom;
  }
`;

export const AboutSection = styled.div`
  min-height: 547px;
  background-color: var(--white-color);
`;

export const GlossarySection = styled.div`
  min-height: 696px;
  background-color: var(--white-color);
`;

export const FeatureSection = styled.div`
  min-height: 491px;
  background-color: var(--white-color);
`;

export const FooterSection = styled.footer`
  min-height: 377px;
  background-color: var(--primary-color);
`;
