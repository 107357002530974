import React from "react";
import Button from "../../atoms/button/Button";
import { TopContentStyles } from "./topContent.styles";

export default function TopContent() {
  return (
    <TopContentStyles>
      <div className="subHeading font-secondary fs-16 fw-400">
        We make technology immutable
      </div>
      <div className="mainHeading-container">
        <div className="fs-64 fs-l-32 font-secondary fw-500 mainHeading">
          Qnucleon’s Move into Quantum-
          <div className="keyword">Resistant Cryptography</div>
        </div>
      </div>
      <div>
        <Button
          theme="primary"
          className="font-primary fw-450 fs-18 px-32 py-13"
        >
          Book Now
        </Button>
      </div>
    </TopContentStyles>
  );
}
