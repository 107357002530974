import styled from "styled-components";

export const AboutContentStyles = styled.div`
  display: flex;
  padding: 80px 120px;

  .about-us {
    max-width: 588px;
    margin-right: 126px;

    .heading {
      line-height: 61px;
      letter-spacing: -0.01em;

      .name {
        color: var(--secondary-color);
        background-image: linear-gradient(
          -225deg,
          #231557 0%,
          #44107a 29%,
          #ff1361 67%,
          #fff800 100%
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textclip 5s linear infinite;
        display: inline-block;
      }
      @media screen and (max-width: 768px) {
        line-height: 35px;
        font-size: 28px !important;
      }

      @keyframes textclip {
        to {
          background-position: 200% center;
        }
      }
    }

    .description {
      line-height: 24px;
      color: #5a5959;
    }
  }

  img {
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 40px 24px;

    .about-us {
      max-width: 100%;
      margin-right: 0;
    }

    .description {
      font-size: 14px !important;
      line-height: 22px;
      margin-bottom: 36px;
    }

    .img-container {
      text-align: center;
      img {
      }
    }
  }
`;
