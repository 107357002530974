import React from "react";
import AboutContent from "../../atoms/aboutContent/AboutContent";
import FeatureContent from "../../atoms/featureContent/FeatureContent";
import FooterContent from "../../molecules/footerContent/FooterContent";
import GlossaryContent from "../../molecules/glossaryContent/GlossaryContent";
import Nav from "../../molecules/navBar/NavBar";
import TopContent from "../../molecules/TopContent/TopContent";
import {
  AboutSection,
  FeatureSection,
  FooterSection,
  GlossarySection,
  TopSection,
} from "./home.styles";

export default function Home() {
  return (
    <>
      {/* Top Section Including the NavBar */}
      <TopSection>
        <Nav />
        <TopContent />
      </TopSection>

      {/* About Section */}
      <AboutSection>
        <AboutContent />
      </AboutSection>

      {/* Glossary Section */}
      <GlossarySection>
        <GlossaryContent
          subHeading={"QNucleon Blockchain Technology"}
          cardsData={[
            {
              Icon: "static/svgs/minus-circle.svg",
              heading: "Quantum Delegate Byzantine Fault Tolerance",
              description:
                "A Quantum-based method for reaching agreements in less rounds tolerating greater faulty players.",
            },
            {
              Icon: "static/svgs/key.svg",
              heading: "Quantum Key Distribution (QKD)",
              description:
                "Leveraging quantum mechanics to allow two parties to create the same arbitrary key.",
            },
            {
              Icon: "static/svgs/bar-chart.svg",
              heading: "Quantum Network",
              description:
                "A Quantum-based method for reaching agreements in less rounds tolerating greater faulty players.",
            },
          ]}
        />
      </GlossarySection>

      {/* Feature Sections */}
      <FeatureSection>
        <FeatureContent
          heading="High ROI"
          description="Do you spend most of your IT budget on maintaining your current system? Many companies find that constant maintenance eats into their budget for new technology. By outsourcing your IT management to us, you can focus on what you do best--running your business."
          Icon="static/svgs/trending-up.svg"
          imageUrl="static/images/high-roi.png"
          link="https://www.blockchain.com/"
        />
      </FeatureSection>
      <FeatureSection>
        <FeatureContent
          contentDirection="row-reverse"
          heading="Satisfaction Guaranteed"
          description="Do you spend most of your IT budget on maintaining your current system? Many companies find that constant maintenance eats into their budget for new technology. By outsourcing your IT management to us, you can focus on what you do best--running your business."
          Icon="static/svgs/smile.svg"
          imageUrl="static/images/satisfaction-guaranteed.png"
          link="https://qnucleon.io/"
        />
      </FeatureSection>

      {/* Glossary Section */}
      <GlossarySection>
        <GlossaryContent
          subHeading={"Blockchain and Crypto Market Development"}
          cardClassName={"cursor-auto"}
          cardsData={[
            {
              imageUrl: "static/images/speculation-and-hyperinflation.png",
              heading: "Speculation & Hyperinflation",
              description:
                "This phase sees the community grow and the technology receive greater attention from the wider",
            },
            {
              imageUrl: "static/images/overcorrection-and-diversification.png",
              heading: "Overcorrection & Diversification",
              description:
                "As with any skyrocketing market, there comes a time where the prices are overcorrected and or a crarlorem ipsum dolar emit",
            },
            {
              imageUrl: "static/images/acceptance-and-governance.png",
              heading: "Acceptance & Governance",
              description:
                "The true value of blockchain technologies place in the wider economy becomes clear an",
            },
          ]}
          btnText="Book Now"
        />
      </GlossarySection>

      {/* Footer Section */}
      <FooterSection>
        <FooterContent />
      </FooterSection>
    </>
  );
}
