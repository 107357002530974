import React, { useState } from "react";
import { CardStyles } from "./card.styles";
import { CardPropTypes } from "./card.types";

export default function Card({
  className,
  Icon,
  imageUrl,
  heading,
  description,
}: CardPropTypes) {
  const [readMore, setReadMore] = useState(false);

  return (
    <CardStyles className={"p-48 fw-450 font-primary " + className}>
      <div>
        <div className="icon-container">
          {typeof Icon === "string" && (
            <img src={Icon} className="icon" alt="" />
          )}
          {typeof Icon === "function" && <Icon />}
        </div>
        {imageUrl && (
          <div className="image-container">
            <img src={imageUrl} alt={heading} />
          </div>
        )}
        <div className="header pt-32 fs-24">{heading}</div>
      </div>
      <div className="description pt-14 fs-16">
        {readMore ? description : description.slice(0, 100)}
        {description.length > 100 && (
          <>
            <span>
              {!readMore && "..."}
              {` `}
            </span>
            <span
              className={`read-more-button cursor-pointer`}
              onClick={() => setReadMore(!readMore)}
            >
              read {readMore ? "less" : "more"}
            </span>
          </>
        )}
      </div>
    </CardStyles>
  );
}
