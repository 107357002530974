import styled from "styled-components";
import { ButtonStylesType } from "./button.types";

export const StyledBtn = styled.div<ButtonStylesType>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 18px;
  gap: 10px;
  border-radius: 4px;
  transition: 0.15s ease all;

  ${({ theme }) =>
    theme === "primary" &&
    `
    background-color: #ffffff;
    color: #384099;
  `}

  ${({ theme }) =>
    theme === "secondary" &&
    `
    background-color: #384499;
    color: #ffffff;
  `}

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    filter: drop-shadow(
      0 0 5px
        ${({ theme }) =>
          theme === "primary"
            ? "var(--secondary-color)"
            : "var(--primary-color)"}
    );
  }
`;
