import React from "react";
import { FooterItemStyles, FooterLinkStyles } from "./footerLink.styles";
import { FooterLinkTypes } from "./footerLink.types";

export default function FooterLink({
  children,
  className,
  href,
  style,
}: FooterLinkTypes) {
  if (href)
    return (
      <FooterLinkStyles style={style} className={className}>
        <a href={href}>{children}</a>
      </FooterLinkStyles>
    );
  else
    return (
      <FooterItemStyles style={style} className={className}>
        {children}
      </FooterItemStyles>
    );
}
