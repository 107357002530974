import React from "react";
import { InputFieldStyles } from "./inputField.styles";
import { InputFieldTypes } from "./inputField.types";

export default function InputField({
  value,
  onChange,
  onSubmit,
  handleSubmit,
  placeholder,
  icon,
  className,
  style,
  type,
}: InputFieldTypes) {
  return (
    <InputFieldStyles onSubmit={onSubmit}>
      <input
        className={className}
        type={type}
        value={value}
        onChange={(e) => onChange && onChange(e)}
        placeholder={placeholder}
      />
      {icon && (
        <img src={icon} alt="submit" title="Submit" onClick={handleSubmit} />
      )}
    </InputFieldStyles>
  );
}
