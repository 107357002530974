import React from "react";
import { SocialIconStyles } from "./socialIcon.styles";
import { SocialIconTypes } from "./socialIcon.types";

export default function SocialIcon({ Icon, link }: SocialIconTypes) {
  return (
    <SocialIconStyles href={link} target="_blank" rel="noreferrer">
      <div className="socialIcon-container">
        {typeof Icon === "string" && <img src={Icon} alt={link} />}
        {typeof Icon === "function" && <Icon />}
      </div>
    </SocialIconStyles>
  );
}
