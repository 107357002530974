import styled from "styled-components";

export const SocialIconStyles = styled.a`
  text-decoration: none;
  font-size: 30px;
  text-align: center;

  .socialIcon-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    transition: 0.15s ease all;

    img {
      padding: 7px;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    .socialIcon-container {
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }
`;
