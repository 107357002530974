import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Button from "../../atoms/button/Button";
import {
  Header,
  MobileNav,
  MobileNavLink,
  MobileNavList,
  Nav,
  NavLink,
  NavList,
  // Seperator,
} from "./navBar.styles";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Header>
      <figure className="logo">
        <Link to="/">
          <img
            src="static/images/white-logo.png"
            width={112}
            height={96}
            alt="logo"
          />
        </Link>
      </figure>
      <Nav>
        <NavList className="font-primary fw-450 fs-16">
          <NavLink>
            <span tabIndex={0}>Home</span>
          </NavLink>
          <NavLink>
            <span tabIndex={0}>Services</span>
          </NavLink>
          <NavLink>
            <span tabIndex={0}>Contact Us</span>
          </NavLink>
          <NavLink>
            <span tabIndex={0}>Bookings</span>
          </NavLink>
          {/* <Seperator />
          <NavLink>
            <span tabIndex={0}>Sign In</span>
          </NavLink> */}
        </NavList>
        {/* <div className="ps-16">
          <Button theme="primary" className="font-primary fw-450 fs-16">
            Create account
          </Button>
        </div> */}
      </Nav>

      <MobileNav active={isOpen}>
        <div className="hamburger-button-container">
          {/* Mobile Logo */}
          <figure className="logo">
            <Link to="/">
              <img
                src="static/images/color-logo.png"
                width={56}
                height={48}
                alt="logo"
              />
            </Link>
          </figure>

          {/* Hamburger Menu Button */}
          <div
            className="hamburger-menu-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <input
              type="checkbox"
              id="checkbox3"
              className="checkbox3 visuallyHidden"
              disabled
              checked={isOpen}
            />
            <label htmlFor="checkbox3">
              <div className="hamburger hamburger3">
                <span className="bar bar1"></span>
                <span className="bar bar2"></span>
                <span className="bar bar3"></span>
                <span className="bar bar4"></span>
              </div>
            </label>
          </div>
        </div>
        <MobileNavList active={isOpen} className="font-primary fw-500 fs-22">
          <ul>
            <MobileNavLink onClick={() => setIsOpen(!isOpen)}>
              <span tabIndex={0}>Home</span>
            </MobileNavLink>
            <MobileNavLink onClick={() => setIsOpen(!isOpen)}>
              <span tabIndex={0}>Services</span>
            </MobileNavLink>
            <MobileNavLink onClick={() => setIsOpen(!isOpen)}>
              <span tabIndex={0}>Contact us</span>
            </MobileNavLink>
            <MobileNavLink onClick={() => setIsOpen(!isOpen)}>
              <span tabIndex={0}>Bookings</span>
            </MobileNavLink>
            {/* <MobileNavLink onClick={() => setIsOpen(!isOpen)}>
              <span tabIndex={0}>Sign In</span>
            </MobileNavLink> */}
          </ul>
        </MobileNavList>
        {/* <div className="pt-20 create-account-container">
          <Button theme="secondary" className="font-primary fw-450 fs-16">
            Create account
          </Button>
        </div> */}
      </MobileNav>
    </Header>
  );
}
