import styled from "styled-components";

export const TopContentStyles = styled.div`
  text-align: center;
  color: var(--white-color);
  padding-top: 16px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }

  .subHeading {
    line-height: 28px;
    padding-bottom: 24px;
    @media screen and (max-width: 768px) {
      padding-bottom: 8px;
    }
  }

  .mainHeading {
    line-height: 76px;
    padding-bottom: 36px;

    @media screen and (max-width: 768px) {
      line-height: 40px;
      padding-bottom: 24px;
      font-size: 32px !important;
    }

    .keyword {
      color: #ffbdb7;
    }
  }
`;
