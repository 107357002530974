import styled from "styled-components";

export const CardStyles = styled.div`
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  transition: 0.15s ease all;

  .icon-container {
    .icon {
      width: 34px;
      height: 34px;
    }
  }

  .image-container {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  .header {
    line-height: 34px;
  }

  .description {
    line-height: 24px;
    color: #646464;
    

    .read-more-button {
      color: var(--primary-color);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    box-shadow: 30px 64px 64px 0px #00000029;
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;

    .image-container {
      width: 100% !important;
      height: auto !important;

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    .header {
      font-size: 20px !important;
      line-height: 30px;
    }

    .description {
      font-size: 14px !important;
      line-height: 22px;
    }

    &:hover {
      box-shadow: 15px 32px 32px 0px #00000029;
    }
`;
