import styled from "styled-components";

export const InputFieldStyles = styled.form`
  position: relative;
  height: 40px;
  width: 255px;

  input {
    width: 100%;
    height: 100%;
    color: white;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    line-height: 24px;
    font-feature-settings: "salt" on, "liga" off;
  }

  input::placeholder {
    color: #d9dbe1;
  }

  input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 125, 0.5);
  }

  img {
    position: absolute;
    cursor: pointer;
    bottom: 11px;
    right: 12px;
  }
`;
